import { Link } from "gatsby"
import React from "react"
import { ReactComponent as ArrowIcon } from "./caret-right-solid.svg"
import SEO from "../seo"
import './breadcrumbs.scss'

const Component = ({ currentItem, currentPath }) => (
    
    <div className="breadcrumbs">
        {currentItem ? <SEO title={currentItem.title} /> : <SEO title={currentPath[currentPath.length - 1].title} /> }
        <section className="custom-container">
            <div>
                <Link to="../">HOME</Link>
                {currentItem ?
                    <span>
                        <ArrowIcon></ArrowIcon>
                        <Link to={currentItem.path}>{currentItem.title}</Link>
                    </span>
                    : null}
                {currentPath ? currentPath.map((item, index) => (
                    <span key={index}>
                        <ArrowIcon></ArrowIcon>
                        <Link to={item.path}>{item.title}</Link>
                    </span>
                )) : null}
            </div>
        </section>
    </div>
)



export default Component
