import React from 'react'

export default () => {
    return (
      <section className="contact-info">
        <h3>
          <strong>MidMichigan Ag Service</strong>
        </h3>
        <address>10522 Roosevelt Rd Carson City, MI 48811</address>
        {/* <br></br> */}
        <span className="font-awesome-wrapper"></span>{" "}
        <a href="tel:+16163256412">616.325.6412</a>
        <p>
          <span className="font-awesome-wrapper"></span>{" "}
          <a href="mailto:info@midmichiganagservice.com">
            info@midmichiganagservice.com
          </a>
        </p>
        <br></br>
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d41553.858190564795!2d-84.76805112366733!3d43.17234221242959!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88227b051c18a6b5%3A0x7dc6c4ac54bb54dc!2s10522%20Roosevelt%20Rd%2C%20Carson%20City%2C%20MI%2048811!5e0!3m2!1sen!2sus!4v1597854601834!5m2!1sen!2sus"
          width="350px"
          height="275px"
          frameBorder="0"
          allowFullScreen=""
          aria-hidden="false"
        ></iframe>
      </section>
    )
}