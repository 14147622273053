import React from 'react'
import { Link } from "gatsby"

export default ({header, menu}) => {
    return <aside>
        <h4>{header}</h4>
        <nav>
            <ul>{menu.map((item, index) => (
                <li key={index}>
                    <Link to={item.path} activeClassName='active'>{item.title}</Link>
                </li>))}
            </ul>
        </nav>
    </aside>
}
