import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import FeaturedImg from "../../components/featuredImg"
export default ({title}) => {

    const data = useStaticQuery(graphql`
    query {
        featuredImgDesktop: file(
            relativePath: { eq: "20-MIM-0008_Secondary_CA-Contact-3.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        featuredImgMobile: file(
            relativePath: { eq: "20-MIM-0008_Mobile-13.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
    `)

    return <FeaturedImg data={data} title={title}></FeaturedImg>
}
