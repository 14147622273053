import React from 'react'
import Img from "gatsby-image"
import "./featuredImg.scss"

export default ({data, title, html}) => {
    return (
        <section className="featured-img">
            <Img fluid={[
                {
                    ...data.featuredImgDesktop.childImageSharp.fluid,
                    key: 1,
                    media: `(min-width: 992px)`
                },
                {
                    ...data.featuredImgMobile.childImageSharp.fluid,
                    key: 2,
                    media: `(max-width: 991px)`
                }
            ]}></Img>
            {title ? <h1>
                {title}
            </h1> : null}
            {html ? <h1 dangerouslySetInnerHTML={{ __html: html }}></h1> : null}
        </section>)
}

